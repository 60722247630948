@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.lab-layout {
    @include mixin.ltBodyTypo(12px, 19.2px);
    color:  variables.$ltGreyScale600;
    a {
        color:  variables.$ltGreyScale600;
    }
    svg {
        width: inherit;
        height: inherit;
        display: block;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include mixin.ltBodyTypo(14px, 22.4px);
        margin: 0;
        color: variables.$ltTitle;
    }
}
