@use '@/services/scss/_variables';

.step {
    width: 220px;
    height: 300px;
    color: variables.$white;
    border-radius: 6px;
    padding: 20px 15px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    h1, h2 {
        color: variables.$white;
        margin: 0 !important;
    }
    .title {
        margin: 0 0 10px;
        display: inline-block;
        background: variables.$ltOrange;
        padding: 5px 24px 5px 15px;
        transform: translateX(-15px);
        border-radius: 0 999px 999px 0;
        * {
            margin: 0;
            color: variables.$white;
        }
    }
    p {
        margin: 5px 0 0;
    }
}
