@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.amount-to-be-paid {
    @include mixin.ltBoxShadow;
    border: 1px solid variables.$ltGreyScale300;
    border-radius: 6px;
    .title {
        padding: 10px;
        border-bottom: 1px solid variables.$ltGreyScale300;
    }
    &-bottom {
        padding: 10px;
        .cod,
        .online {
            @include mixin.itemsCenter;
            gap: 5px;
            font-weight: 500;
            color: variables.$ltTitle;
            input {
                width: 16px;
                height: 16px;
                accent-color: variables.$ltPrimary;
            }
        }
        .online {
            .payment-option {
                @include mixin.ltBodyTypo(14px, 22.4px);
            }
        }
        img {
            width: 100%;
            height: 100%;
            padding: 10px 0 0;
            object-fit: contain;
        }
    }
}
