@use '@/services/scss/_variables';

.order-success {
    width: 100%;
    display: flex;
    flex-direction: column;
    // align-items: center;
    text-align: center;
    color:  variables.$ltGreyScale600;
    &-top {
        width: 100%;
        padding: 0 0 20px;
        border-bottom: 1px solid variables.$ltGreyScale300;
        .order-success-graphics {
            width: 250px;
            height: 250px;
            margin: 0 auto;
            margin-bottom: -55px;
            svg {
                width: 100%;
                height: 100%;
                display: block;
            }
        }
        h2 {
            color: variables.$ltTitle;
            font-size: 16px;
            font-weight: 600;
            margin: 0 0 10px;
            line-height: 24px;
        }
        p {
            margin: 0;
            font-size: 14px;
            line-height: 22.4px;
        }
    }
    &-bottom {
        width: 100%;
        margin: 25px 0 0;
        text-align: left;
        padding: 0 16px;
        .order-info {
            gap: 10px;
            display: flex;
            font-size: 12px;
            margin: 0 0 25px;
            line-height: 19.2px;
            flex-direction: column;
            .order-id,
            .order-item,
            .order-sample-collection,
            .order-confirmation {
                gap: 10px;
                display: flex;
                div:first-child {
                    color: variables.$ltTitle;
                    font-weight: 500;
                }
            }
        }
        .order-success-actions {
            gap: 10px;
            display: flex;
            div {
                display: block;
                text-align: center;
                width: 100%;
                font-size: 14px;
                font-weight: 600;
                padding: 10px 20px;
                border-radius: 6px;
                line-height: 22.4px;
                border: 1px solid;
                &:first-child {
                    color: variables.$ltOrange;
                    background: rgba(254, 150, 74, 0.1);
                }
                &:last-child {
                    color: variables.$white;
                    background: variables.$ltPrimary;
                    border-color: variables.$ltPrimary;
                }
            }
        }
    }
}
