@use '@/services/scss/_variables';

.empty-screen {
    width: 100%;
    .graphic {
        margin: 0 auto 30px;
        svg {
            width: 100%;
            height: 100%;
            display: block;
        }
    }
    &-message-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .empty-screen-message {
            margin: 0 auto;
            color: variables.$ltTitle;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            padding: 10px 36px;
            border-radius: 6px;
            position: relative;
            line-height: 22.4px;
            background: variables.$ltOrangeFaded;
            &::before,
            &::after {
                top: 50%;
                content: '';
                height: 1px;
                width: 20px;
                position: absolute;
                background: variables.$ltOrange;
            }
            &::before {
                left: 10px;
            }
            &::after {
                right: 10px;
            }
        }
    }
    a {
        color: variables.$white;
        display: block;
        font-size: 14px;
        padding: 10px 0;
        margin: 0 0 16px;
        font-weight: 600;
        text-align: center;
        border-radius: 6px;
        line-height: 22.4px;
        margin: 14px 0 0;
        background: variables.$ltPrimary;
    }
}
