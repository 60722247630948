@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.drawer-wrapper {
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    .drawer {
        position: fixed;
        bottom: 0;
        width: 100%;
        max-height: calc(100% - 24px);
        .icon {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            background: variables.$ltTitle;
            @include mixin.flexCenter;
            margin: 0 auto 16px;
            svg {
                path {
                    fill: variables.$white;
                }
            }
        }
        .drawer-body {
            background: variables.$white;
            border-radius: 12px 12px 0 0;
            overflow: hidden;
        }
    }
}
