@use "@/services/scss/variables.scss";

.chip {
    display: inline-block;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 6px;
    text-transform: capitalize;
    &.red {
        background: #fff0f0 !important;
        color: variables.$ltError;
    }
    &.blue {
        background: rgba(56, 189, 248, 0.1);
        color: #38bdf8;
    }
    &.orange {
        background: rgba(255, 149, 74, 0.1);
        color: #fe964a;
    }
    &.primary {
        background: rgb(29, 160, 153, 0.1);
        color: variables.$ltPrimary;
    }
    &.yellow {
        background: #fffae9;
        color: #e6bb20;
    }
    &.purple {
        color: #8c62ff;
        background: #f3efff;
    }
}
