@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.payment-details {
    border: 1px solid variables.$ltGreyScale300;
    border-radius: 6px;
    @include mixin.ltBoxShadow;
    color: variables.$ltTitle;
    .title {
        padding: 10px;
        border-bottom: 1px solid variables.$ltGreyScale300;
        @include mixin.flexCenterBetween;
        @include mixin.ltBodyTypo(14px, 22.4px);
        font-weight: 600;
        color: variables.$ltTitle;
        gap: 10px;
    }
    .coupon-code-wrapper {
        padding: 10px 10px 0;
        .toggler {
            color: variables.$ltPrimary;
            text-decoration: underline;
        }
        .coupon-field-wrapper {
            @include mixin.itemsCenter;
            border: 1px solid variables.$ltGreyScale300;
            border-radius: 6px;
            overflow: hidden;
            margin: 5px 0 0;
            .icon {
                padding: 5px;
            }
            input {
                width: calc(100% - 34px - 35px);
                border: none;
                &:focus {
                    outline: none;
                }
            }
            .apply-btn,
            .remove-btn {
                padding: 10px;
                border: none;
            }
            .apply-btn {
                background: variables.$ltPrimary;
                color: variables.$white;
            }
            .remove-btn {
                background: variables.$ltError;
                color: variables.$white;
            }
        }
    }
    .pricing {
        .consultation-fees,
        .payable-amounts,
        .other-amounts,
        .convenience-amounts,
        .cancellation-reason-wrapper {
            padding: 10px;
            &:not(:last-child) {
                border-bottom: 1px;
                border-bottom-color: variables.$ltGreyScale300;
                border-bottom-style: solid;
            }
            & > div {
                @include mixin.flexCenterBetween;
                &:not(:last-child) {
                    margin: 0 0 8px;
                }
            }
            .discount,
            .coupon {
                .amount {
                    color: variables.$ltPrimary;
                }
            }
        }
        .convenience-amounts {
            .amount {
                color: variables.$ltError;
            }
        }
        .other-amounts {
            border-bottom-style: dashed !important;
            .amount {
                color: variables.$ltPrimary;
            }
        }
        .payable-amounts,
        .cancellation-reason-wrapper {
            .payable-amount,
            .cancellation-reason-label {
                font-weight: 600;
                .amount {
                    color: variables.$ltPrimary;
                }
            }
            .paid-amount {
                span {
                    color: variables.$ltPrimary;
                }
            }
        }
        .cancellation-reason-wrapper {
            .cancellation-reason {
                color: variables.$ltError;
            }
        }
    }
}
