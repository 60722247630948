@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.lab-order-success-wrapper {
    padding: 16px 0 0;
    .lab-order-blocks {
        overflow: auto;
        @include mixin.flexColumn;
        gap: 16px;
        padding: 0 0 16px;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .lab-order-success-actions {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        box-shadow: 4px 0px 15px 0px #191d1d1a;
        padding: 10px;
        @include mixin.flexColumn;
        gap: 16px;
        background: variables.$white;
        button, a {
            padding: 10px 12px;
            background: variables.$ltPrimary;
            border-radius: 6px;
            border: 1px solid variables.$ltPrimary;
            color: variables.$white;
            text-align: center;
            &:last-child {
                border: 1px solid variables.$ltPrimary;
                background: none;
                color: variables.$ltPrimary;
            }
        }
    }
}
