@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.discount-chip-v2 {
    display: flex;
    svg {
        width: inherit;
        height: inherit;
        &:nth-child(1) {
            margin: 0 -1px 0 0;
        }
    }
    span {
        font-size: 8px;
        line-height: 10px;
        background: variables.$ltError;
        color: variables.$white;
        @include mixin.itemsCenter;
    }
}
