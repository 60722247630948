@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.lab-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    font-size: 12px;
    line-height: 19.2px;
    background: rgb(18, 24, 38, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    .lab-modal {
        border-radius: 12px;
        overflow: hidden;
        min-width: 250px;
        max-width: calc(100% - 32px);
        background: variables.$white;
        position: relative;
        .close-button {
            width: 48px;
            height: 48px;
            @include mixin.flexCenter;
            position: absolute;
            top: 0;
            right: 0;
            background: none;
            border: none;
        }
    }
}
