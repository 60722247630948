@use "@/services/scss/_mixin";
@use "@/services/scss/_variables";

.order-reschedule-action {
    padding: 10px 12px;
    width: 100%;
    background: variables.$ltPrimary;
    color: variables.$white;
    border: none;
    border-radius: 6px;
    margin: 10px 0 0;
}
