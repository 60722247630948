@use '@/services/scss/_variables';

.book-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    font-size: 12px;
    line-height: 19.2px;
    background: rgb(18, 24, 38, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    .book-modal {
        border-radius: 6px;
        overflow: hidden;
        min-width: 250px;
        max-width: calc(100% - 32px);
        background: variables.$white;
        position: relative;
        &-full {
            width: 100%;
        }
        &-head {
            padding: 10px 16px;
            border-bottom: 1px solid variables.$ltGreyScale300;
            display: flex;
            align-items: center;
            justify-content: space-between;
            svg {
                display: block;
                path {
                    fill: variables.$ltError;
                }
            }
        }
        &-bottom {
            .spinner-wrapper {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #fff;
            }
        }
    }
}
