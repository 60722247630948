@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.lab-partner-item {
    @include mixin.itemsCenter;
    gap: 5px;
    border: 1px solid variables.$ltGreyScale300;
    border-radius: 6px;
    padding: 8px;
    @include mixin.ltBoxShadow;
    .partner-logo {
        width: 40px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid variables.$ltGreyScale300;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .partner-info {
        width: calc(100% - 50px);
        .partner-name {
            font-weight: 600;
            font-size: 12px;
            color: variables.$ltTitle;
            margin: 0 0 2px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .available-tests {
            font-size: 10px;
        }
    }
}
