@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.order-details-address {
    border: 1px solid variables.$ltGreyScale300;
    border-radius: 6px;
    @include mixin.ltBoxShadow;
    .address-title-wrapper {
        @include mixin.flexCenterBetween;
        border-bottom: 1px solid variables.$ltGreyScale300;
        padding: 10px;
        .address-title {
            @include mixin.ltBodyTypo(14px, 22.4px);
            font-weight: 600;
            color: variables.$ltTitle;
        }
        button {
            padding: 10px 12px;
            border-radius: 6px;
            border: 1px solid variables.$ltError;
            color: variables.$ltError;
            background: none;
        }
    }
    .address-wrapper {
        padding: 10px;
        @include mixin.itemsCenter;
        gap: 10px;
    }
    .note-wrapper {
        padding: 10px;
        .note {
            padding: 5px 10px;
            border: 1px solid variables.$ltGreyScale300;
            background: variables.$secondary100;
            border-radius: 6px;
        }
    }
}
