@use '@/services/scss/_variables';

.health-concern-card,
.vital-organ-card {
    gap: 5px;
    padding: 10px;
    display: flex;
    min-height: 125px;
    border-radius: 6px;
    position: relative;
    align-items: center;
    flex-direction: column;
    .thumbnail {
        width: 100%;
        overflow: hidden;
        aspect-ratio: 1 / 1;
        border-radius: 100%;
        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
        }
    }
    p {
        display: block;
        color: variables.$ltTitle;
        max-height: 38px;
        margin: auto 0 0;
        font-weight: 600;
        text-align: center;
        overflow: hidden;
    }
}

.health-concern-card {
    background: #eff8f8;
}

.vital-organ-card {
    flex-direction: column-reverse;
    p {
        margin: 0 0 auto;
        text-align: center;
        display: block;
        color: variables.$ltTitle;
        font-weight: 600;
    }
    &:nth-child(1) {
        background: #c8d7f5;
        p {
            color: #4b5f80;
        }
    }
    &:nth-child(2) {
        background: #f9e0e6;
        p {
            color: #a97d8a;
        }
    }
    &:nth-child(3) {
        background: #dcd8f3;
        p {
            color: #625e90;
        }
    }
    &:nth-child(4) {
        background: #f8e9c8;
        p {
            color: #9d844c;
        }
    }
    &:nth-child(5) {
        background: #f8e9ca;
        p {
            color: #9d8755;
        }
    }
    &:nth-child(6) {
        background: #dad5f3;
        p {
            color: #5f6090;
        }
    }
    &:nth-child(7) {
        background: #c8d7f4;
        p {
            color: #475b80;
        }
    }
    &:nth-child(8) {
        background: #f9e0e6;
        p {
            color: #aa828d;
        }
    }
}
