@use '@/services/scss/_variables';

.patients-list {
    gap: 10px;
    display: flex;
    background: variables.$white;
    font-size: 12px;
    padding-bottom: 83px;
    padding-top: 16px;
    line-height: 19.2px;
    flex-direction: column;
    a {
        gap: 10px;
        text-align: center;
        color: variables.$white;
        border: 1px solid;
        .dropdown-icon {
            svg {
                width: 15px;
                height: 15px;
                display: block;
            }
        }
    }
    .spinner-wrapper {
        display: flex;
        padding: 16px 0;
        min-height: 80px;
        align-items: center;
        justify-content: center;
    }
}
.no-items-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 218px);
}
.spinner-wrapper {
    display: flex;
    padding: 16px 0;
    min-height: 80px;
    align-items: center;
    justify-content: center;
}
