@use '@/services/scss/_variables';

.life-style-package {
    background: variables.$white;
    border: 1px solid variables.$ltGreyScale300;
    border-radius: 6px;
    overflow: hidden;
    img {
        display: block;
        object-fit: cover;
    }
    .title {
        padding: 5px;
        display: flex;
        color: variables.$ltTitle;
        text-align: center;
        align-items: center;
        justify-content: center;
        .icon {
            svg {
                display: block;
            }
            &.male-icon {
                svg {
                    path {
                        fill: variables.$purple;
                    }
                }
            }
            &.female-icon {
                svg {
                    path {
                        fill: variables.$ltError;
                    }
                }
            }
        }
        h2 {
            font-size: 12px;
            line-height: 19.2px;
            font-weight: 600;
            margin: 0;
        }
    }
}
