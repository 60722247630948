@use '@/services/scss/_variables';

.checkout {
    gap: 16px;
    display: flex;
    flex-direction: column;
    color:  variables.$ltGreyScale600;
    font-size: 12px;
    line-height: 19.2px;
    background: variables.$white;
    .schedule,
    .hard-copy,
    .amount-to-be-paid {
        background: variables.$white;
        border-radius: 6px;
        box-shadow: 0 2px 15px rgb(0, 0, 0, 0.1);
        overflow: hidden;
        &-top {
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .schedule {
        &-top {
            border-bottom: 1px solid variables.$ltGreyScale300;
        }
        &-bottom {
            .title {
                padding: 10px 10px 0;
                font-weight: 600;
                color: variables.$ltTitle;
            }
            .schedule-dates,
            .schedule-times {
                gap: 10px;
                display: grid;
                overflow: scroll;
                padding: 10px;
                grid-template-columns: minmax(100px, max-content);
                /* &::-webkit-scrollbar {
                    display: none;
                } */
                .schedule-date,
                .schedule-time {
                    padding: 10px;
                    border-radius: 6px;
                    border: 1px solid variables.$ltGreyScale300;
                    color: variables.$ltTitle;
                    &.active {
                        background: variables.$ltPrimary;
                        color: variables.$white;
                    }
                }
            }
        }
    }
    .amount-to-be-paid {
        box-shadow: none;
        &-top {
            background: #fff0f0;
            border-top: 1px solid variables.$ltGreyScale300;
            border-left: 1px solid variables.$ltGreyScale300;
            border-right: 1px solid variables.$ltGreyScale300;
            border-radius: 6px 6px 0 0;
            h2 {
                color: variables.$ltError;
            }
        }
        &-bottom {
            padding: 10px;
            border-left: 1px solid variables.$ltGreyScale300;
            border-right: 1px solid variables.$ltGreyScale300;
            border-bottom: 1px solid variables.$ltGreyScale300;
            border-radius: 0px 0px 6px 6px;
            .cod,
            .online {
                display: flex;
                align-items: center;
                gap: 10px;
                font-weight: 500;
                color: variables.$ltTitle;
                input {
                    width: 16px;
                    height: 16px;
                    accent-color: variables.$ltPrimary;
                }
            }
            .cod {
                margin: 0 0 10px;
            }
            img {
                width: 100%;
                padding: 10px 0 0;
            }
            .warning-message {
                padding: 10px;
                border-radius: 6px;
                display: flex;
                align-items: center;
                gap: 10px;
                margin: 10px 0 0;
                color: variables.$ltError;
                background: variables.$ltErrorFaded;
                svg {
                    path {
                        fill: variables.$ltError;
                    }
                }
            }
        }
    }
    .button {
        gap: 10px;
        text-align: center;
        padding: 10px 15px;
        color: variables.$white;
        font-size: 14px;
        line-height: 22.4px;
        border: 1px solid;
        border-radius: 6px;
        background: variables.$ltPrimary;
        width: 100%;
        .dropdown-icon {
            svg {
                width: 15px;
                height: 15px;
                display: block;
            }
        }
    }
}
