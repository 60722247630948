@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.dropdown-btn, .book-btn {
    border: none;
    width: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    // font-size: 12px;
    font-weight: 600;
    border-radius: 6px;
    padding: 10px 12px;
    @include mixin.ltBodyTypo($lineHeight: 20px);
}

.book-btn {
    color: variables.$white;
    background: variables.$ltPrimary;
}

.dropdown-btn {
    gap: 10px;
    display: flex;
    background: none;
    color: variables.$ltPrimary;
    align-items: center;
    border: 1px solid variables.$ltPrimary;
    padding: 7px 12px 7px 20px;
    .icon {
        svg {
            display: block;
            path {
                fill: variables.$ltPrimary;
            }
        }
    }
}

.book-btn.btn-large, .dropdown-btn.btn-large {
    width: 150px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
