@use "@/services/scss/_variables";
@use "@/services/scss/_mixin";

.vendors-modal {
    overflow: auto;
    .title {
        border-bottom: 1px solid variables.$ltGreyScale300;
        padding: 16px;
        position: sticky;
        top: 0;
        background: variables.$white;
    }
    .vendors {
        padding: 16px 16px 85px;
        @include mixin.flexColumn;
        gap: 10px;
        .lab,
        .lab-active {
            border-radius: 6px;
            border: 1px solid variables.$ltGreyScale300;
            @include mixin.ltBoxShadow;
            padding: 10px;
            @include mixin.itemsCenter;
            gap: 10px;
            .lab-info {
                width: 100%;
                @include mixin.itemsCenter;
                gap: 10px;
                .lab-image {
                    width: 48px;
                    height: 48px;
                    border: 1px solid variables.$ltGreyScale300;
                    overflow: hidden;
                    border-radius: 6px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .lab-content {
                    width: calc(100% - 58px);
                    @include mixin.flexCenterBetween;
                    .lab-name {
                        @include mixin.ltBodyTypo(14px, 22.4px);
                        font-weight: 600;
                        color: variables.$ltTitle;
                    }
                    .pricing-wrapper {
                        text-align: right;
                    }
                }
            }
        }
        .lab-active {
            border-color: variables.$ltPrimary;
        }
    }
    .vendors-modal-action {
        position: fixed;
        bottom: 0;
        width: 100%;
        border: 1px solid variables.$ltGreyScale300;
        background: variables.$white;
        padding: 16px;
        button {
            padding: 10px 12px;
            border: none;
            width: 100%;
            border-radius: 6px;
            background: variables.$ltPrimary;
            color: variables.$white;
            font-weight: 600;
        }
    }
}
