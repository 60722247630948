@use '@/services/scss/_variables';

.show-tests {
    color: variables.$ltPrimary;
    .icon {
        width: 16px;
        height: 16px;
        margin: 1px 0 0;
        svg {
            width: 100%;
            height: 100%;
            display: block;
            path {
                fill: variables.$ltPrimary;
            }
        }
    }
    &.underline {
        text-decoration: underline;
    }
}