@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.shopping-cart-tab {
    gap: 10px;
    padding: 5px;
    display: flex;
    font-size: 12px;
    line-height: 19.2px;
    margin: 0 0 10px;
    align-items: center;
    border-radius: 999px;
    border: 1px solid variables.$ltGreyScale300;
    @include mixin.ltBoxShadow;
    a {
        gap: 5px;
        width: 100%;
        padding: 8px;
        display: flex;
        font-weight: 600;
        border-radius: 999px;
        color:  variables.$ltGreyScale600;
        justify-content: center;
        .icon {
            width: 20px;
            height: 20px;
            color: variables.$white;
            display: flex;
            align-items: center;
            border-radius: 100%;
            background: variables.$ltError;
            justify-content: center;
        }

        &.active_medicine {
            background: variables.$primary;
            color: variables.$white;
            .icon {
                background-color: variables.$white;
                color: variables.$error;
                @include mixin.itemsCenter;
                justify-content: center;
            }
        }
        &.active {
            color: variables.$white;
            background: variables.$ltPrimary;
            .icon {
                background-color: variables.$white;
                color: variables.$error;
                @include mixin.itemsCenter;
                justify-content: center;
            }
        }
    }
}
