@use '@/services/scss/_mixin';
@use '@/services/scss/_variables';

.test-list-wrapper {
    padding-top: 10px;
}

.spinner-wrapper {
    display: flex;
    padding: 16px 0;
    min-height: 80px;
    align-items: center;
    justify-content: center;
}

.filters {
    position: sticky;
    top: 55px;
    z-index: 1;
    background: variables.$white;
    display: flex;
    gap: 10px;
    overflow: auto;
    border-bottom: 1px solid variables.$ltGreyScale300;
    padding: 1px 16px 10px;
    &::-webkit-scrollbar {
        display: none;
    }
}
