@use '@/services/scss/_variables';

.patient-form {
    padding: 16px;
    background: variables.$white;
    border-radius: 6px;
    margin: 0 0 96px;
    box-shadow: 0 2px 15px rgb(0, 0, 0, 0.1);
    .avatar-wrapper {
        gap: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            border-radius: 100%;
        }
        label {
            font-weight: 600;
            color: variables.$ltPrimary;
        }
    }
    & > div {
        gap: 10px;
        display: flex;
        position: relative;
        flex-direction: column;
        &:not(:last-child) {
            margin: 0 0 14px;
        }
        label {
            color: variables.$ltTitle;
            font-weight: 500;
            span {
                color: variables.$ltError;
            }
        }
        input, select {
            padding: 10px 16px;
            border-radius: 6px;
            border: 1px solid variables.$ltGreyScale300;
            &:focus {
                outline: none;
            }
            &::placeholder {
                color: #a0aec0;
            }
            &.gender,
            &.relation {
                text-transform: capitalize;
            }
        }
        svg {
            top: 39.5px;
            width: 16px;
            height: 16px;
            right: 10px;
            position: absolute;
        }
    }
    .submit {
        gap: 10px;
        width: 100%;
        display: flex;
        padding: 10px;
        color: variables.$white;
        display: block;
        border: 1px solid;
        border-radius: 6px;
        align-items: center;
        background: variables.$ltPrimary;
        .dropdown-icon {
            svg {
                width: 15px;
                height: 15px;
                display: block;
            }
        }
    }
}
