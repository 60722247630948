@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.cart-list {
    margin: 0 0 10px;
    border-radius: 6px;
    @include mixin.ltBoxShadow;
    border: 1px solid variables.$ltGreyScale300;
    .change-lab-wrapper {
        border-bottom: 1px solid variables.$ltGreyScale300;
        padding: 10px;
        .select-lab-wrapper {
            background: #f5f8ff;
            @include mixin.flexCenterBetween;
            border-radius: 6px;
            padding: 8px 10px;
            .selected-lab {
                gap: 5px;
                @include mixin.itemsCenter;
                color: variables.$ltTitle;
                .lab-info {
                    .lab-name {
                        font-weight: 600;
                    }
                }
            }
            button {
                gap: 5px;
                @include mixin.itemsCenter;
                padding: 0;
                border: none;
                background: none;
                color: #0062ff;
            }
        }
    }
}

.add-test-btn {
    color: variables.$white !important;
    display: block;
    font-size: 14px;
    padding: 10px 0;
    font-weight: 600;
    text-align: center;
    border-radius: 6px;
    line-height: 22.4px;
    background: variables.$ltPrimary;
}
