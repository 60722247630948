@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.manage-patient-card {
    background: variables.$white;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid variables.$ltGreyScale300;
    @include mixin.ltBoxShadow;
    display: flex;
    align-items: center;
    gap: 10px;
    img {
        border-radius: 100%;
        object-fit: cover;
    }
    .patient-info {
        .patient-details {
            text-transform: capitalize;
        }
    }
    a {
        width: 36px;
        height: 36px;
        background: #e8f5f5;
        display: flex;
        padding: 0;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        margin: 0 0 0 auto;
        svg {
            width: 16px;
            height: 16px;
        }
    }
}
