@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.floating-cart-wrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 1px solid variables.$ltGreyScale300;
    padding: 10px;
    background: variables.$white;
    z-index: 1;
    svg {
        display: block;
    }
    .floating-cart {
        display: flex;
        color: variables.$white;
        padding: 10px 16px;
        border-radius: 6px;
        align-items: center;
        background: variables.$ltPrimary;
        justify-content: space-between;
        .cart-info {
            gap: 10px;
            display: flex;
            font-size: 12px;
            line-height: 18px;
            align-items: center;
            .cart-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 6px;
                font-size: 16px !important;
                border-radius: 6px;
                background: #12918d;
                svg {
                    path {
                        fill: variables.$white;
                    }
                }
            }
        }
        a {
            color: variables.$white;
            display: flex;
            font-size: 12px;
            align-items: center;
            .icon {
                width: 24px;
                height: 24px;
                svg {
                    width: 100%;
                    height: 100%;
                    display: block;
                    path {
                        fill: variables.$white;
                    }
                }
            }
        }
    }
}
