@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.discount-badge {
    @include mixin.flexColumn;
    max-width: max-content;
    margin: 0 0 0 5px;
    svg:first-child {
        margin: 0 0 -1px 0;
    }
    .discount-percent {
        background: #0062FF;
        color: variables.$white;
        text-align: center;
        font-weight: 700;
        font-size: 6px;
        line-height: 6px;
        div:last-child {
            text-transform: uppercase;
        }
    }
}
