@use '@/services/scss/_variables';

.lab-toast {
    width: 320px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .toast-message {
        gap: 10px;
        display: flex;
        align-items: center;
    }
    .icon {
        svg {
            display: block;
        }
        &.add {
            svg {
                path {
                    fill: variables.$ltPrimary;
                }
            }
        }
        &.remove {
            svg {
                path {
                    fill: variables.$ltError;
                }
            }
        }
    }
    a {
        color: variables.$warning200;
    }
}
