@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.vendors {
    display: flex;
    gap: 10px;
    margin: 5px 0;
    .vendor,
    .overflow-vendor {
        border: 1px solid variables.$ltGreyScale300;
        width: 22px;
        height: 22px;
        border-radius: 3px;
    }
    .overflow-vendor {
        @include mixin.flexCenter;
    }
}
