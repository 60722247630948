@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    @include mixin.flexCenter;
    overflow: auto;
    .modal-content-wrapper {
        background: variables.$white;
        border-radius: 6px;
        width: calc(100% - 32px);
        position: relative;
        color: variables.$ltTitle;
        .close-btn {
            position: absolute;
            top: 10px;
            right: 10px;
            @include mixin.flexCenter;
            width: 40px;
            height: 40px;
            background: none;
            border: none;
        }
        .modal-content {
            padding: 16px;
            .icon-wrapper {
                width: 58px;
                height: 58px;
                border-radius: 100%;
                background: rgba(253, 79, 79, 0.1);
                @include mixin.flexCenter;
            }
            .desc {
                margin: 5px 0 0;
            }
        }
        .cancellation-reasons {
            padding: 16px;
            border-top: 1px solid variables.$ltGreyScale300;
            .label {
                font-weight: 500;
                color: variables.$ltTitle;
                margin: 0 0 5px;
                span {
                    color: variables.$ltError;
                }
            }
            .selector {
                position: relative;
                border: 1px solid variables.$ltGreyScale300;
                border-radius: 6px;
                padding: 8px 12px;
                @include mixin.flexCenterBetween;
                .reasons {
                    background: variables.$white;
                    border: 1px solid variables.$ltGreyScale300;
                    border-radius: 6px;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    top: calc(100% + 5px);
                    padding: 5px;
                    .reason {
                        padding: 10px;
                        border-radius: 6px;
                        @include mixin.flexCenterBetween;
                        &.active {
                            background: #f9fafb;
                        }
                    }
                }
            }
        }
        .other-description {
            padding: 0 16px 16px;
            .label {
                font-weight: 500;
                color: variables.$ltTitle;
                margin: 0 0 5px;
                span {
                    color: variables.$ltError;
                }
            }
            textarea {
                width: 100%;
                border: 1px solid variables.$ltGreyScale300;
                border-radius: 6px;
                padding: 8px 12px;
                height: 82px;
                &::placeholder {
                    font-family: 'Inter', sans-serif;
                }
                &:focus {
                    outline: none;
                }
            }
        }
        .modal-actions {
            padding: 16px;
            border-top: 1px solid variables.$ltGreyScale300;
            @include mixin.itemsCenter;
            gap: 10px;
            button {
                width: 100%;
                padding: 10px 12px;
                border: 1px solid variables.$ltGreyScale300;
                border-radius: 6px;
                background: none;
                &:last-child {
                    background: variables.$ltPrimary;
                    color: variables.$white;
                }
            }
        }
    }
}
