@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.order-via-item {
    height: 40px;
    width: 100%;
    border: 1px solid variables.$ltGreyScale300;
    border-radius: 6px;
    @include mixin.flexCenter;
    gap: 5px;
    color: inherit;
    .icon {
        svg {
            display: block;
        }
    }
}
