@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.floating-cart {
    width: 100%;
    display: flex;
    color: variables.$white;
    padding: 10px 16px;
    border-radius: 6px;
    align-items: center;
    background: variables.$ltPrimary;
    justify-content: space-between;
    .cart-info {
        gap: 10px;
        @include mixin.itemsCenter;
        .cart-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px;
            font-size: 16px !important;
            border-radius: 6px;
            background: #12918d;
            svg {
                path {
                    fill: variables.$white;
                }
            }
        }
    }
    .lab-cart-footer-action {
        @include mixin.itemsCenter;
        background: none;
        border: none;
        color: variables.$white;
    }
}
