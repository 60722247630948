@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.overview {
    border: 1px solid variables.$ltGreyScale300;
    @include mixin.ltBoxShadow;
    gap: 10px;
    border-radius: 6px;
    padding: 10px 16px;
    display: flex;
    align-items: flex-start;
    .icon {
        svg {
            display: block;
        }
    }
    .title {
        @include mixin.flexCenterBetween;
        margin: 0 0 10px;
    }
    .description {
        width: 100%;
        &.deactive {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
        a {
            color: variables.$ltPrimary;
        }
        p {
            margin-bottom: 0;
        }
        img {
            width: 100%;
        }
    }
}
