@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.schedule {
    border: 1px solid variables.$ltGreyScale300;
    @include mixin.ltBoxShadow;
    border-radius: 6px;
    &-top {
        padding: 10px;
        border-bottom: 1px solid variables.$ltGreyScale300;
        margin: 0 0 10px;
    }
    &-bottom {
        .title {
            padding: 0 10px;
            font-weight: 600;
            color: variables.$ltTitle;
        }
        .schedule-dates,
        .schedule-times {
            gap: 10px;
            display: grid;
            overflow: scroll;
            padding: 10px;
            grid-template-columns: minmax(100px, max-content);
            .schedule-date,
            .schedule-time {
                padding: 10px;
                border-radius: 6px;
                border: 1px solid variables.$ltGreyScale300;
                color: variables.$ltTitle;
                &.active {
                    background: variables.$ltPrimary;
                    color: variables.$white;
                }
            }
        }
    }
}
