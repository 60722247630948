@use '@/services/scss/variables';
@use '@/services/scss/mixin.scss';

.lab-test-card {
    background: variables.$white;
    border: 1px solid variables.$ltGreyScale300;
    border-radius: 6px;
    color:  variables.$ltGreyScale600;
    @include mixin.ltBoxShadow;
    a {
        color:  variables.$ltGreyScale600;
        font-size: 10px;
        .card-top,
        .card-bottom {
            padding: 10px;
            display: flex;
        }
        .card-top {
            gap: 10px;
            .test-image {
                width: 90px;
                height: 90px;
                overflow: hidden;
                border-radius: 6px;
                background: variables.$ltGreyScale300;
                border: 1px solid variables.$ltGreyScale300;
                position: relative;
            }
            .test-info {
                max-width: calc(100% - 100px);
                @include mixin.flexColumn;
                justify-content: space-between;
                h1 {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    font-size: 12px;
                    margin: 0;
                }
                .pricing {
                    gap: 5px;
                    display: flex;
                    position: relative;
                    align-items: center;
                    .discount-price {
                        color: variables.$ltTitle;
                        font-weight: 600;
                    }
                    .regular-price {
                        text-decoration: line-through;
                    }
                }
                .warning {
                    font-weight: 500;
                }
            }
        }
        .card-bottom {
            @include mixin.flexCenterBetween;
            border-top: 1px solid variables.$ltGreyScale300;
            .hint-message {
                color: variables.$ltError;
                font-weight: 500;
            }
            .test-info {
                .report-time,
                .booked-time {
                    gap: 5px;
                    @include mixin.itemsCenter;
                    .icon {
                        width: 16px;
                        height: 16px;
                        svg {
                            width: 100%;
                            height: 100%;
                            display: block;
                            path {
                                fill:  variables.$ltGreyScale600;
                            }
                        }
                    }
                }
                .booked-time {
                    .icon {
                        svg {
                            path {
                                fill: variables.$warning200;
                            }
                        }
                    }
                }
            }
        }
    }
}

.package-badge {
    display: flex;
    position: absolute;
    bottom: 5px;
    z-index: 1;
    svg {
        width: inherit;
        height: inherit;
    }
    span {
        background: variables.$ltOrange;
        color: variables.$white;
        @include mixin.ltBodyTypo(10px, 16px);
    }
}
