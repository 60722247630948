@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.filtering-options {
    // @include mixin.itemsCenter;
    display: flex;
    gap: 10px;
    .filtering-btn {
        border: 1px solid variables.$ltGreyScale300;
        border-radius: 6px;
        padding: 8px;
        @include mixin.itemsCenter;
        gap: 5px;
        @include mixin.ltBodyTypo(10px, 16px);
        .icon {
            width: 12px;
            height: 12px;
            svg {
                width: 100%;
                height: 100%;
                display: block;
            }
        }
        div {
            width: max-content;
        }
    }
    .filtered-values {
        display: flex;
        gap: 10px;
        @include mixin.ltBodyTypo(10px, 16px);
        .value {
            gap: 5px;
            @include mixin.itemsCenter;
            border: 1px solid variables.$ltPrimary;
            border-radius: 6px;
            background: #0e76730f;
            padding: 0 8px;
            .vendor-logo {
                width: 16px;
                height: 16px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .icon {
                svg {
                    display: block;
                }
            }
            div {
                width: max-content;
            }
        }
    }
}
