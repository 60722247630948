@use "@/services/scss/variables.scss";
@use "@/services/scss/mixin.scss";

.order_summary {
    @include mixin.boxShadow;
    @include mixin.borderRadius;
    @include mixin.border;
    background-color: variables.$white;

    h3 {
        padding: 10px;
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 1px solid variables.$grey200;
    }

    .details {
        border-bottom: 1px solid variables.$grey200;
        padding: 10px;
        p {
            margin: 0 0 10px 0;
            font-size: 12px;
            @include mixin.flexBetween;
            text-transform: capitalize;

            &:last-child {
                margin: 0;
            }
            .red {
                color: variables.$error;
            }
            .success {
                color: variables.$primary;
            }
        }
    }
    .total {
        padding: 10px;

        div {
            @include mixin.flexBetween;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 5px;

            span {
                &:last-child {
                    color: variables.$primary;
                }
            }
        }
        .info_single {
            margin: 0;
            font-size: 10px;
            font-weight: 500;
            @include mixin.itemsCenter;
            color: variables.$dark;
            background-color: rgba($color: variables.$blue, $alpha: 0.1);
            border-radius: 4px;
            padding: 4px;
            svg {
                min-width: 16px;
                min-height: 16px;
                max-width: 16px;
                max-height: 16px;
                margin-right: 5px;
                color: variables.$blue;
            }
        }
    }
}
