@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.category-layout {
    display: grid;
    grid-template-columns: 70px auto;
    gap: 5px;
    .sort-filter-buttons-wrapper {
        position: sticky;
        top: 55px;
        background: variables.$white;
        z-index: 2;
        padding: 5px 0 0;
    }
    .test-list-wrapper {
        padding: 10px 16px 0 0;
    }
    .spinner-wrapper {
        display: flex;
        padding: 16px 0;
        min-height: 80px;
        align-items: center;
        justify-content: center;
    }
}
