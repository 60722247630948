@use '@/services/scss/_mixin';
@use '@/services/scss/_variables';

.lab-cart {
    padding: 0 0 96px;
    @include mixin.ltBodyTypo;
    color: variables.$ltGreyScale600;
    @include mixin.flexColumn;
    gap: 16px;
}
.not-found-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: calc(100vh - 296px);
}
