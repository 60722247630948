@use '@/services/scss/_variables';

.sortable-btns {
    gap: 5px;
    top: 50px;
    z-index: 1;
    width: 100%;
    padding: 5px 0 10px 0;
    display: flex;
    position: sticky;
    align-items: center;
    background: #F9FAFB;
    div {
        gap: 4px;
        display: flex;
        padding: 5px 10px;
        border-radius: 6px;
        background: variables.$white;
        align-items: center;
        border: 1px solid variables.$ltGreyScale300;
        .icon {
            width: 12px;
            height: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                display: block;
            }
        }
    }
}
