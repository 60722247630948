@use '@/services/scss/_variables';

.test-details {
    color:  variables.$ltGreyScale600;
    img {
        width: 100%;
        height: 100%;
        display: block;
    }
    .test-analytics {
        display: flex;
        color: variables.$white;
        padding: 10px 16px;
        align-items: center;
        background: variables.$dark;
        border-radius: 6px 6px 0 0;
        justify-content: space-between;
        .report-available-hour,
        .total-booked-time {
            gap: 5px;
            display: flex;
            align-items: center;
            .icon {
                width: 16px;
                height: 16px;
                svg {
                    width: 100%;
                    height: 100%;
                    display: block;
                    path {
                        fill: variables.$warning200;
                    }
                }
            }
        }
        .total-booked-time {
            .icon {
                margin: 0 0 1px;
            }
        }
    }
    .test-info {
        padding: 10px 0;
        .subtitle {
            margin: 0;
            color:  variables.$ltGreyScale600;
        }
    }
    .vendors-wrapper {
        padding: 10px 0;
        border-top: 1px solid variables.$ltGreyScale300;
        border-bottom: 1px solid variables.$ltGreyScale300;
    }
    .fasting-sample {
        gap: 16px;
        display: flex;
        padding: 10px 0;
        align-items: flex-start;
        .divider {
            width: 1px;
            height: 60px;
            background: variables.$ltGreyScale300;
        }
        .fasting,
        .sample {
            h2 {
                margin-bottom: 5px;
            }
            div {
                padding: 3px 10px;
                font-size: 10px;
                line-height: 16px;
                border-radius: 999px;
                display: inline-block;
                border: 1px solid variables.$ltGreyScale300;
            }
            .test-requirments {
                gap: 5px;
                padding: 0;
                border: none;
                display: flex;
                flex-wrap: wrap;
            }
            .requirment {
                text-transform: capitalize;
            }
        }
    }
    .tests {
        padding: 10px 0;
        border-bottom: 1px solid variables.$ltGreyScale300;
        .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            div {
                color: variables.$ltTitle;
                font-weight: 500;
            }
            span {
                display: flex;
                font-weight: 500;
                color: variables.$ltPrimary;
                align-items: center;
            }
            .show-tests {
                display: flex;
                color: variables.$ltPrimary;
                align-items: center;
            }
        }
        .bottom {
            padding: 10px 0 0;
            ul {
                padding: 0;
                display: grid;
                margin: 0 0 0 16px;
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
    .test-pricing {
        display: flex;
        padding: 10px 0;
        align-items: center;
        color:  variables.$ltGreyScale600;
        justify-content: space-between;
        border-bottom: 1px solid variables.$ltGreyScale300;
    }
}
