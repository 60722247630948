@use '@/services/scss/_mixin';
@use '@/services/scss/_variables';

.lab-tests {
    padding: 0 0 96px;
    @include mixin.ltBodyTypo;
    &.sortable-lab-tests {
        padding: 0 5px 96px;
    }
    .spinner-wrapper {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .no-item-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 221px);
    }
}
