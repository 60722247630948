@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.congratulation-card {
    border: 1px solid variables.$ltGreyScale300;
    @include mixin.ltBoxShadow;
    padding: 24px 10px;
    border-radius: 6px;
    .animated-graphic {
        width: 150px;
        height: 150px;
        margin: -56px auto -40px;
    }
    .congratulation-content {
        text-align: center;
        .congratulation-title {
            @include mixin.ltBodyTypo(18px, 27px);
            font-weight: 600;
            color: variables.$ltTitle;
        }
        .congratulation-message {
            span {
                color: variables.$ltTitle;
            }
        }
    }
    .steps-wrapper {
        margin: 16px 0 0;
        .steps {
            @include mixin.flexCenter;
            gap: 24px;
            .step {
                @include mixin.flexColumn;
                align-items: center;
                .step-title-active {
                    color: #0ba259;
                }
            }
        }
    }
    .order-details {
        margin: 16px 0 0;
        .order-id,
        .order-amount,
        .order-item,
        .confirmation {
            display: flex;
            gap: 10px;
            &:not(:last-child) {
                margin-bottom: 10px;
            }
            .label {
                font-weight: 500;
                color: variables.$ltTitle;
            }
        }
    }
    .details-toggler {
        @include mixin.flexCenter;
        color: variables.$ltPrimary;
        margin: 16px 0 0;
    }
}
