@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.address-card {
    background: variables.$white;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid variables.$ltGreyScale300;
    @include mixin.ltBoxShadow;
    &-title {
        border-bottom: 1px solid variables.$ltGreyScale300;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: variables.$ltTitle;
        .edit-icon {
            display: flex;
            align-items: center;
            color: variables.$ltPrimary;
            gap: 5px;
            .icon {
                width: 16px;
                height: 16px;
                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    &-address {
        display: flex;
        padding: 10px;
        gap: 10px;
    }
    .textarea {
        position: relative;
        padding: 0 10px 10px;
        span {
            position: absolute;
            bottom: 20px;
            right: 24px;
        }
        textarea {
            display: block;
            height: 80px;
            width: 100%;
            border-radius: 8px;
            border: 1px solid variables.$ltGreyScale300;
            resize: none;
            padding: 8px 14px;
            background: #fffae9;
            &.red {
                border-color: variables.$ltError;
            }
            &:focus {
                outline: none;
            }
            &::placeholder {
                font-family: 'Inter', sans-serif;
                color:  variables.$ltGreyScale600;
            }
        }
    }
    .address-not-set {
        padding: 10px;
        span {
            display: block;
            color: variables.$ltTitle;
            margin: 0 0 10px;
            font-weight: 500;
        }
        a {
            display: block;
            color: #ffffff;
            display: block;
            font-size: 14px;
            padding: 10px 0;
            font-weight: 600;
            text-align: center;
            border-radius: 6px;
            line-height: 22.4px;
            background: #0e7673;
        }
    }
}
