@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.service-highlights {
    border: 1px solid variables.$ltGreyScale300;
    @include mixin.ltBoxShadow;
    border-radius: 6px;
    display: flex;
    padding: 10px;
    gap: 24px;
    .divider {
        width: 1px;
        background-color: variables.$ltGreyScale300;
    }
    .covid,
    .report {
        width: calc(100% - 2px);
        @include mixin.itemsCenter;
        gap: 10px;
        .icon-wrapper {
            width: 48px;
            height: 48px;
            @include mixin.flexCenter;
            background: #f3f9f8;
            border-radius: 100%;
        }
        .content {
            .title {
                color: variables.$ltPrimary;
                font-weight: 600;
            }
        }
    }
}
