@use '@/services/scss/_variables';

.health-concern-card {
    overflow: hidden;
    border-radius: 6px;
    background: #F9FAFB;
    img {
        width: 100%;
        display: block;
        object-fit: cover;
    }
    .title {
        height: calc(100% - 66px);
        display: flex;
        padding: 5px;
        color: variables.$ltTitle;
        font-weight: 600;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
}