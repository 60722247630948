@use '@/services/scss/_mixin';
@use '@/services/scss/_variables';

.order-details-template {
    @include mixin.flexColumn;
    gap: 10px;
    padding: 5px 0 16px;
    .payment-actions {
        margin: 10px 0 0;
        button {
            gap: 10px;
            padding: 10px;
            border-radius: 6px;
            background-color: variables.$ltPrimary;
            border: none;
            color: variables.$white;
            width: 100%;
        }
    }
}
