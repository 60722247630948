@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.hard-copy {
    background: variables.$white;
    display: block;
    border-radius: 6px;
    @include mixin.ltBoxShadow;
    border: 1px solid variables.$ltGreyScale300;
    overflow: hidden;
    position: relative;
    &-top {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    h2 {
        color: variables.$ltError;
    }
    &-bottom {
        padding: 0 10px 10px;
    }
    .checkbox {
        gap: 10px;
        display: flex;
        align-items: center;
        input {
            width: 16px;
            height: 16px;
            margin: 0;
            accent-color: variables.$ltPrimary;
        }
    }
    .spinner-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
