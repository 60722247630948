@use '@/services/scss/_variables';
@use '@/services/scss/_mixin';

.cart-item {
    gap: 10px;
    width: 100%;
    display: flex;
    padding: 10px;
    color:  variables.$ltGreyScale600;
    &:not(:last-child) {
        border-bottom: 1px solid variables.$ltGreyScale300;
    }
    .test-image {
        width: 70px;
        height: 70px;
        overflow: hidden;
        position: relative;
        border-radius: 6px;
        background: variables.$ltGreyScale300;
        .discount-badge-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
        & > img {
            border: 1px solid variables.$ltGreyScale300;
            border-radius: 6px;
        }
    }
    .test-info {
        display: flex;
        flex-direction: column;
        width: calc(100% - 73px);
        &-top {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            div {
                color: variables.$ltTitle;
                font-weight: 600;
            }
            .icon {
                svg {
                    display: block;
                    path {
                        fill:  variables.$ltGreyScale600;
                    }
                }
            }
        }
        &-middle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .subtitle {
                width: 150px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
            .pricing {
                gap: 5px;
                display: flex;
                align-items: center;
                .subtotal-amount {
                    text-decoration: line-through;
                }
                .total-amount {
                    color: variables.$ltTitle;
                    font-weight: 600;
                }
            }
        }
        &-bottom {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            button {
                width: 93px;
                height: 28px;
                font-size: 10px;
                line-height: 16px;
                padding: 0 0 0 9px;
                border-radius: 4px;
            }
        }
        &-bottom-most {
            margin: 5px 0 0;
            font-size: 10px;
            color: variables.$ltError;
            line-height: 16px;
        }
    }
}
